<template>
    <el-form
        class="msg-box"
        v-if="formModel"
        :rules="formRules"
        :model="formModel"
        ref="formModel"
        label-width="140px"
        label-suffix="："
        @submit.native.stop.prevent="handleMsgSubmit('formModel')"
    >

        <el-form-item label="通知主题">
            <el-col :span="9">
                <el-input
                    class="msg-input"
                    v-model="formModel.title"
                    placeholder="请为通知命名"
                ></el-input>
            </el-col>
        </el-form-item>

        <el-form-item label="开启状态">
            <el-radio-group v-model="formModel.status">
                <el-radio :label="0">关闭</el-radio>
                <el-radio :label="1">开启</el-radio>
            </el-radio-group>
        </el-form-item>

        <el-form-item label="发送方式">
            <el-checkbox-group v-model="formModel.send_type">
                <el-checkbox label="1">服务通知</el-checkbox>
                <el-checkbox label="2">服务号</el-checkbox>
                <el-checkbox label="3">短信</el-checkbox>
            </el-checkbox-group>
        </el-form-item>

        <el-form-item label="发送时间及内容">
            <div class="b-card">
                <!-- 新增 -->
                <div
                    style="margin-bottom:20px;"
                    v-for="(item) in formModel.m_body"
                    :key="item.key"
                >
                    <div
                        class="b-card"
                    >
                        <div>
                            <el-cascader
                                style="margin:0 20px 20px 20px;width:300px;"
                                :options="formModel.selectArr"
                                v-model="item.selectedOptions"
                            >
                            </el-cascader>

                            <el-select
                                style="margin:0 20px 20px 20px;width:180px;"
                                v-model="item.timeType"
                                placeholder="请选择"
                            >
                                <el-option
                                    v-for="item in TimeType"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>

                            <el-input
                                v-if="item.timeType != 0"
                                style="margin:0 20px 20px 20px;width:100px;"
                                type="number"
                                :min="0"
                                v-model="item.body"
                                placeholder=''
                            ></el-input>

                            <el-select
                                style="margin:0 20px 20px 20px;width:100px;"
                                v-if="item.timeType != 0"
                                v-model="item.timeUnit"
                                placeholder="请选择"
                            >
                                <el-option
                                    v-for="item in TimeUnit"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select>
                            <el-button style="color:#F56C6C;" type="text" icon="el-icon-delete" @click.prevent="removeBody(item)">删除</el-button>
                        </div>
                        <div v-if="formModel.send_type.includes('1')">
                             <el-select
                                style="margin:0 20px 20px 20px;width:80%"
                                v-model="item.mini_temp_id"
                                placeholder="请选择服务模板"
                                @change="handleChange(item)"
                            >
                                <el-option
                                    v-for="temp in formModel.tempList"
                                    :key="temp.template_id"
                                    :label="temp.title"
                                    :value="temp.template_id"
                                >
                                </el-option>
                            </el-select>
                            <div class="tempBox" v-if="item.mini_temp_body">
                                <p v-for="select in item.mini_temp_body.example" :key="select">
                            {{select}}</p>
                            </div>
                        </div>

                         <div v-if="formModel.send_type.includes('2')">
                             <el-select
                                style="margin:0 20px 20px 20px;width:80%"
                                v-model="item.fwh_temp_id"
                                placeholder="请选择服务号模板"
                                @change="handleFwhChange(item)"
                            >
                                <el-option
                                    v-for="temp in formModel.fwhList"
                                    :key="temp.template_id"
                                    :label="temp.title"
                                    :value="temp.template_id"
                                >
                                </el-option>
                            </el-select>
                        </div>

                        <div v-if="formModel.send_type.includes('3')">
                             <el-select
                                style="margin:0 20px 20px 20px;width:80%"
                                v-model="item.sms_temp_id"
                                placeholder="请选择短信模板"
                                @change="handleSmsChange(item)"
                            >
                                <el-option
                                    v-for="temp in formModel.smsList"
                                     :key="temp.tempKey"
                                      :label="temp.title"
                                      :value="temp.tempKey"
                                      >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>

                <!-- 按钮 -->
                <el-button type="primary" icon="el-icon-plus" plain @click="addBody">添加</el-button>
            </div>
        </el-form-item>

        <el-form-item>
            <el-button
                size="medium"
                type="primary"
                native-type="submit"
                :loading="submitLoading"
            >{{ submitText }}</el-button>
        </el-form-item>

    </el-form>
</template>

<script>
export default {
  name: 'MsgFrom',
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object
    }
  },
  data () {
    const TimeType = [
      {
        id: 0,
        label: '发生时'
      },
      {
        id: 1,
        label: '发生后'
      }
    ]
    const TimeUnit = [
      {
        id: 0,
        label: '分'
      },
      {
        id: 1,
        label: '时'
      },
      {
        id: 2,
        label: '天'
      }
    ]
    return {
      submitLoading: false,
      formModel: null,
      formRules: null,
      TimeUnit: TimeUnit,
      TimeType: TimeType
    }
  },

  async mounted () {
    this.formModel = Object.assign(this.model)
  },

  methods: {
    handleChange (item) {
      this.formModel.tempList.forEach(element => {
        if (element.template_id === item.mini_temp_id) {
          item.mini_temp_body = element
        }
      })
    },
    handleFwhChange (item) {
      if (this.formModel.fwuhList) {
        this.formModel.fwhList.forEach(element => {
          if (element.template_id === item.fwh_temp_id) {
            item.fwh_temp_body = element
          }
        })
      }
    },
    handleSmsChange (item) {
      if (this.formModel.smsList) {
        this.formModel.smsList.forEach(element => {
          if (element.tempKey === item.sms_temp_id) {
            item.sms_temp_body = element
          }
        })
      }
    },
    handleMsgSubmit (formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false
        }

        this.submitLoading = true
        let param = Object.assign({}, this.formModel)
        delete (param.tempList)
        delete (param.selectArr)
        this.$emit(
          'on-submit',
          param,
          () => {
            this.$refs[formName].clearValidate()
          },
          () => {
            this.submitLoading = false
          }
        )
      })
    },
    removeBody (item) {
      var index = this.formModel.m_body.indexOf(item)
      if (index !== -1) {
        this.formModel.m_body.splice(index, 1)
      }
    },
    addBody () {
      this.formModel.m_body.push({
        body: ''
      })
    }
  }
}
</script>
<style lang='scss'>
.msg-box {
    .msg-input > input {
        border: 1px solid #fff !important;
        border-bottom: 1px solid #eaeaea !important;
        box-shadow: none !important;
    }
    .tempBox{
        background: #f6f6f6;
        padding: 20px;
        margin-bottom: 20px;
        p{
            line-height: 0.7em;
        }
    }
}
</style>
