<template>
 <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <msg-form
      v-if="model"
      :submit-text="pageTitle"
      :model="model"
      :is-edit="true"
      @on-submit="createMsgCreate"
    >
    </msg-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>

import MsgService from '@admin/services/MsgService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'
import MsgForm from './components/_Form'

export default {
  name: '',
  data () {
    return {
      pageTitle: '编辑',
      model: null
    }
  },

  components: {
    MsgForm, PlaceholderForm
  },

  async created () {
    const { data } = await flatry(MsgService.edit(this.$router.currentRoute.query.id))
    if (data) {
      this.model = data.data
    }
  },

  methods: {
    async createMsgCreate (model, success, callback) {
      const { data } = await flatry(MsgService.edit(model.id, model))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({
          path: '/msg-temp/update',
          query: { id: data.data }
        })

        success()
      }

      callback()
    }
  },

  watch: {}

}

</script>
<style lang='' scoped>

</style>
